.react-multiple-carousel__arrow--left{
    left: 0px;
}

.react-multiple-carousel__arrow--right{
    right: 0px;
}

.react-multi-carousel-track{
    padding-left: 48px;
    padding-right: 48px;
    align-items: center;
}

.react-multiple-carousel__arrow{
    background: rgba(0,0,0, 0) !important;
    color: #717073 !important;
}

.react-multi-carousel-item{
 /* width: 150px !important; */
}